@import "../entrypoint_init";

$theme-gotham-primary: hsl(252deg 3% 37%);
$theme-gotham-primary-filter: brightness(0%) saturate(100%) invert(32%) sepia(7%)
  saturate(302%) hue-rotate(211deg) brightness(104%) contrast(84%);
$theme-gotham-secondary: hsl(205deg 27% 46%);
$theme-gotham-secondary-filter: brightness(0%) saturate(100%) invert(41%) sepia(55%)
  saturate(273%) hue-rotate(162deg) brightness(100%) contrast(93%);

.theme {
  &.theme-gotham {
    @include theme-variables(
      $theme-gotham-primary,
      $theme-gotham-secondary,
      $theme-gotham-primary-filter,
      $theme-gotham-secondary-filter
    );
    @include theme($theme-gotham-primary, $theme-gotham-secondary);
  }
}

.memorial-theme-option {
  &[data-theme-name="theme-gotham"] {
    @include theme-preview($theme-gotham-primary, $theme-gotham-secondary);
  }
}
